<!-- 检索学员出勤统计表详情 -->
<template>
    <div class="RetrievalTableDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">检索学员出勤统计表详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" height="650" >
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="sign_status" sortable label="签到状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.sign_status==1">签到</span>
                    <span v-else-if="scope.row.sign_status==2">请假</span>
                    <span v-else-if="scope.row.sign_status==3">未到</span>
                    <span v-else-if="scope.row.sign_status==4">休学</span>
                </template>
            </el-table-column>
            <el-table-column prop="student_card" sortable label="学员卡号" align="center" width="120">
            </el-table-column>
            <el-table-column prop="student_name" :sortable="true" :sort-method="fn2"
             label="学员姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="sign_type" sortable label="上课类" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.sign_type==1">常规</span>
                    <span v-else-if="scope.row.sign_type==2">试学</span>
                    <span v-else-if="scope.row.sign_type==3">补课</span>
                </template>
            </el-table-column>
            <el-table-column prop="course_time" sortable label="上课时间" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_date" sortable label="上课日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="number" sortable label="单号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="document_time" sortable label="制单日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="class_name" sortable label="培训班名称" align="center" width="240">
            </el-table-column>
            <el-table-column prop="resources" sortable label="授课老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course" sortable label="学科" align="center" width="120">
            </el-table-column>
            <el-table-column prop="remark" sortable label="说明" align="center" width="300">
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tableData:[],//表格数据
                currentPage: 1,
                currentLimit:20,
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
                this.$request({
                    url:'/api/classsign/statisticsDetail',
                    method:'POST',
                    data:{
                        id:this.$route.query.id
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'列表')
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            }
        }
    }
</script>

<style scoped="scoped">
    .RetrievalTableDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
 /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
